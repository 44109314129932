import React from "react"
import { Link } from "gatsby"
import { Layout, Head, ResizedImage, Breadcrumbs, EmailPopUp } from "@common"
import { BlogSection, CallTherapistSection, NewsletterSection } from "@sections"
import "./styles/categories.scss"

const CategoryItem = ({ count, image, slug, name }) => {
  if (count) {
    return (
      <div className="categories-item">
        <Link to={`/categories/${slug}/`}>
          <div className="categories-image">
            <ResizedImage {...image} size={"300w"} />
            <h6 className="categories-counter">{count}</h6>
          </div>
          <h4 className="categories-title">{name}</h4>
        </Link>
      </div>
    )
  } else {
    return null
  }
}

const Categories = ({ pageContext }) => {
  const { page, categories, posts } = pageContext
  const _cat = categories.filter(el => el.count)
  return (
    <Layout title={"SKARBY DJINNA"} parallaxImg={page.featuredImage}>
      <EmailPopUp />
      <Head
        seo={pageContext.page.seo}
        siteMetaData={pageContext.page.siteMetaData}
      />
      <div className="categories">
        <div className="categories-content">
          <div className="container">
            <Breadcrumbs
              elements={[{ label: "Skarby", url: "/categories/" }]}
            />
            <div className="categories-list">
              {_cat.map((el, k) => (
                <CategoryItem {...el} key={k} />
              ))}
            </div>
          </div>
        </div>
        <CallTherapistSection />
        <BlogSection posts={posts} />
        <NewsletterSection />
      </div>
    </Layout>
  )
}
export default Categories
